import "core-js/modules/es.array.push.js";
import ParentListContent from "../../components/parentListContent";
import certificationComp from '../../components/certification-comp';
export default {
  name: 'index',
  components: {
    ParentListContent,
    certificationComp
  },
  data() {
    return {
      contentBoxheight: window.innerHeight - 200 * window.innerWidth / 750,
      pageLoading: true,
      itemList: [],
      certificationAuto: false
    };
  },
  async created() {
    // let result=await this.$request({
    //     method: 'get',
    //     url:'/app/api/my/orders',
    // })
    // console.log(result);
    // this.dataList=result.data
    // this.pageLoading=false
  },
  async mounted() {},
  methods: {
    onDataLoad: async function (options, callback) {
      const result = await this.$request({
        url: '/app/api/my/orders',
        method: 'get',
        params: options
      });
      callback(result);
    },
    pushData(items, type) {
      console.log(items);
      console.log(type);
      if (type === 'set') this.itemList = items;
      if (type === 'add') this.itemList = this.itemList.concat(items);
      // this.itemList = items
    },

    copySuccess() {
      this.$toast({
        message: '复制成功'
      });
    },
    async goDetail(item) {
      this.$router.push('/detail/' + item.id);
    }
  }
};