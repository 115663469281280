var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "wrap"
  }, [_c('ticket-step-comp', {
    attrs: {
      "index": 0
    }
  }), !_vm.$store.state.userInfo.company && _vm.$store.state.userInfo.type != 3 ? _c('div', {
    staticClass: "no-data"
  }, [_c('img', {
    staticClass: "icon",
    attrs: {
      "src": "https://qiniu.touchdot.top/code-cdn/hongrun-gifttwo/icon/icon-8.png"
    }
  }), _c('div', {
    staticClass: "text"
  }, [_vm._v("认证后即可查看您的礼品")]), _c('div', {
    staticClass: "but",
    on: {
      "click": function ($event) {
        _vm.certificationAuto = true;
      }
    }
  }, [_vm._v("认证")])]) : _c('div', {
    staticClass: "content-box"
  }, [_c('parent-list-content', {
    ref: "parentListContent",
    on: {
      "onDataLoad": _vm.onDataLoad,
      "pushData": _vm.pushData
    }
  }, [_c('div', {
    staticClass: "item-list"
  }, _vm._l(_vm.itemList, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "item",
      on: {
        "click": function ($event) {
          return _vm.goDetail(item);
        }
      }
    }, [_c('div', {
      staticClass: "info"
    }, [_c('div', {
      staticClass: "title"
    }, [_vm._v(_vm._s(item.activity.name))]), item.expired && item.state == 0 ? _c('div', {
      staticClass: "state",
      staticStyle: {
        "background": "#999999"
      }
    }, [_vm._v("已过期")]) : item.state == 0 ? _c('div', {
      staticClass: "state"
    }, [_vm._v("立即领取")]) : item.state == 1 ? _c('div', {
      staticClass: "state",
      staticStyle: {
        "background": "#D6BC75"
      }
    }, [_vm._v("待发货")]) : item.state == 2 ? _c('div', {
      staticClass: "state",
      staticStyle: {
        "background": "#D6BC75"
      }
    }, [_vm._v("待收货")]) : item.state == 3 ? _c('div', {
      staticClass: "state",
      staticStyle: {
        "background": "#0d9200"
      }
    }, [_vm._v("已完成")]) : _vm._e()]), item.state == 0 ? _c('div', {
      staticClass: "time"
    }, [_vm._v("领奖截止时间：" + _vm._s(item.expiredTime || item.activity.endTime))]) : _vm._e(), item.state == 0 ? _c('div', {
      staticClass: "gift-box"
    }, [_c('div', {
      staticClass: "tit"
    }, [_vm._v("活动礼品")]), _c('div', {
      staticClass: "list"
    }, [_vm._l(item.activity.goods.filter((obj, i) => {
      return i < 3;
    }), function (site) {
      return _c('div', {
        key: site.id,
        staticClass: "li"
      }, [_c('van-image', {
        attrs: {
          "width": "100%",
          "height": "100%",
          "fit": "cover",
          "src": site.cover
        }
      })], 1);
    }), item.activity.goods.length >= 4 ? _c('div', {
      staticClass: "li more"
    }, [_c('div', {
      staticClass: "dot"
    }), _c('div', {
      staticClass: "dot"
    }), _c('div', {
      staticClass: "dot"
    })]) : _vm._e()], 2)]) : _vm._e(), item.state > 0 ? _c('div', {
      staticClass: "logistics-data-box"
    }, [item.state == 1 ? _c('div', {
      staticClass: "item-li"
    }, [_c('img', {
      staticClass: "icon",
      attrs: {
        "src": "https://qiniu.touchdot.top/code-cdn/hongrun-gifttwo/icon/icon-2.png"
      }
    }), _c('div', {
      staticClass: "li-info"
    }, [_c('div', {
      staticClass: "name-phone"
    }, [_c('div', {
      staticClass: "name"
    }, [_vm._v(_vm._s(item.username))]), _c('div', {
      staticClass: "phone"
    }, [_vm._v(_vm._s(item.phone))])]), _c('div', {
      staticClass: "address"
    }, [_vm._v(_vm._s(item.address))])])]) : _vm._e(), item.state == 2 ? _c('div', {
      staticClass: "item-li"
    }, [_c('img', {
      staticClass: "icon",
      attrs: {
        "src": "https://qiniu.touchdot.top/code-cdn/hongrun-gifttwo/icon/icon-3.png"
      }
    }), _c('div', {
      staticClass: "li-info",
      on: {
        "click": function ($event) {
          $event.stopPropagation();
        }
      }
    }, [_c('div', {
      staticClass: "name-phone"
    }, [_c('div', {
      staticClass: "name"
    }, [_vm._v(_vm._s(item.expressCompany))])]), _c('div', {
      staticClass: "address"
    }, [_vm._v("运单号：" + _vm._s(item.expressCode))]), _c('div', {
      directives: [{
        name: "clipboard",
        rawName: "v-clipboard:copy",
        value: item.expressCode,
        expression: "item.expressCode",
        arg: "copy"
      }, {
        name: "clipboard",
        rawName: "v-clipboard:success",
        value: _vm.copySuccess,
        expression: "copySuccess",
        arg: "success"
      }],
      staticClass: "copy"
    }, [_vm._v("复制单号 ")])])]) : _vm._e(), item.state > 0 ? _c('div', {
      staticClass: "user-gift"
    }, [_c('div', {
      staticClass: "tit"
    }, [_vm._v("我的礼品")]), _c('div', {
      staticClass: "list"
    }, _vm._l(item.goods, function (site) {
      return _c('div', {
        key: site.id,
        staticClass: "li"
      }, [_c('div', {
        staticClass: "cover"
      }, [_c('van-image', {
        attrs: {
          "width": "100%",
          "height": "100%",
          "fit": "cover",
          "src": site.goodCover
        }
      })], 1), _c('div', {
        staticClass: "name"
      }, [_vm._v(_vm._s(site.goodName))])]);
    }), 0)]) : _vm._e()]) : _vm._e()]);
  }), 0)])], 1), _vm.itemList.length == 0 && _vm.pageLoading == false ? _c('div', {
    staticClass: "no-data"
  }, [_c('img', {
    staticClass: "icon",
    attrs: {
      "src": "https://qiniu.touchdot.top/code-cdn/hongrun-gifttwo/icon/icon-8.png"
    }
  }), _c('div', {
    staticClass: "text"
  }, [_vm._v("😭 还没获得礼品呢 😭")])]) : _vm._e(), _vm.certificationAuto && !_vm.$store.state.userInfo.company ? _c('certification-comp', {
    on: {
      "close": function ($event) {
        _vm.certificationAuto = false;
      }
    }
  }) : _vm._e(), _c('tabbar-comp', {
    attrs: {
      "index": 0
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };